import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import {
  alpha,
  Box,
  Button,
  ButtonBase,
  colors,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import TranslatedText from 'components/atoms/TranslatedText';
import UploadedFilesAction from '../../store/actions';
import { DefaultPaging } from '../../configuration';
import {
  openDiscoverImagesModal,
  closeDiscoverImagesModal,
} from 'components/compounds/DiscoverImagesModal';
import PublicIcon from '@material-ui/icons/Public';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { withRoles } from 'navigation/middlewares';
import { Roles } from 'constants/authRolesConst';
import { useContextProps } from '../../provider';

interface IShowWarningsButtonProps {}

const ShowWarningsButton = withRoles(
  ({}: IShowWarningsButtonProps) => {
    const dispatch = useDispatch();
    const [isOrphanCheck, setIsOphanCheck] = React.useState(false);
    const { setParams, refetch, ...contextParams } = useContextProps();

    const handleClickOpen = () => {
      const _isOrphanCheck = !isOrphanCheck;
      // dispatch(
      //   UploadedFilesAction.fetchFilesAction({
      //     payload: {
      //       ...contextParams,
      //       isOrphanCheck: _isOrphanCheck ? 'true' : 'false',
      //     },
      //     onSuccess: () => {},
      //   })
      // );
      setParams &&
        setParams({
          ...contextParams,
          isOrphanCheck: _isOrphanCheck ? 'true' : 'false',
        });
      setIsOphanCheck(_isOrphanCheck);
    };

    return (
      <>
        <Button
          startIcon={
            isOrphanCheck ? (
              <CheckBoxIcon color="primary" />
            ) : (
              <CheckBoxOutlineBlankIcon />
            )
          }
          variant="outlined"
          onClick={handleClickOpen}
        >
          <TranslatedText defaultText={'Show Warings'} />
        </Button>
      </>
    );
  },
  [Roles.Admin]
);

export default ShowWarningsButton;
